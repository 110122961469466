import Layout from "../components/layout"
import React from "react"
import useStations from "../utils/hooks/useStations"

const StateCoordinatorResourcesPage = () => {
  const { station } = useStations()
  return (
    <Layout station={station}>
      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">
        <h1>NEWA State Coordinator Resources</h1>
        <h2>Management</h2>
            <a href="https://lookerstudio.google.com/reporting/79129d83-66c4-4226-8b1b-df8ac46689a7">Weather station metrics</a><br></br>
            <a href="https://lookerstudio.google.com/reporting/2041d04b-68c2-4ce8-b9bf-36e74ff7d86b">Digital resource metrics</a><br></br><br></br>
      </div>
      
    </Layout>
  )
}

export default StateCoordinatorResourcesPage
